import React from "react";
import { Godavariheader,Godavaricontactus, Propertiesfooter } from "../../components";
import './Godavaricontact.scss';


const Godavaricontact = () => {
    return (
        <div> 
            <Godavariheader />
            <Godavaricontactus/>
            <Propertiesfooter />
        </div>
    );
};

export default Godavaricontact;